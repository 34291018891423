import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import StepMenu from "../stepMenu";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

import { toast } from "react-hot-toast";
import { Spin, Select } from "antd";

import ConfirmModal from "../../confirmModal";
import {
  lettersOnly,
  lowerCase,
  momentDate,
  numberToMonth,
  upperCase,
} from "../../../utils";
import { COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY } from "../../../utils/data";
import { Link } from "react-router-dom";

const { Option } = Select;

const StepTwo = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [captcha, setCaptcha] = useState([]);
  const [applicant, setApplicant] = useState([]);
  const [hscSubjects, setHscSubjects] = useState([]);

  const [minMark, setMinMarks] = useState(1);
  const [maxMark, setMaxMark] = useState(200);
  const [community, setCommunity] = useState("");
  const [subjectCount, setSubjectCount] = useState(0);
  const [board, setBoard] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [savedMarks, setSavedMarks] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  const getMarksInfo = (sId, info = false) => {
    return {
      subject_id: sId,
      subjectname: "",
      subject_information: "",
      mark_scored: "",
      mark_maximum: maxMark,
      pass_month: 4,
      pass_year: new Date().getFullYear(),
      register_no: "",
      no_of_attempts: "",
      scored_total: "",
      max_total: "",
      part3_total: "",
      percentage: "",
    };
  };

  const [marks, setMarks] = useState([]);

  useEffect(() => {
    loadApplicantInfo();
    loadHscSubjects();

    if (props.pageType && props.pageType == "edit") loadData();
  }, []);

  const loadData = () => {
    setDataLoader(true);
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.UG.PERSONAL_MARKS_PREVIEW_NEW, form).then((res) => {
      if (res["data"].status == "1") {
        let d = res["data"].data.basic;
        let m = res["data"].data.marks;
        setBaisc(res["data"].data.basic);
        setSavedMarks(res["data"].data.marks);
        setCommunity(d.community);
        setBoard(d.board);

        let mr = [];
        m.map((item, i) => {
          mr.push({
            id: item.id,
            subject_id: item.subject_id,
            subjectname: item.subjectname,
            mark_scored: parseFloat(item.mark_scored),
            mark_maximum: item.mark_maximum,
            pass_month: item.pass_month,
            pass_year: item.pass_year,
            register_no: item.register_no,
            no_of_attempts: item.no_of_attempts,
            scored_total: item.scored_total,
            max_total: item.max_total,
            part3_total: item.part3_total,
            percentage: item.percentage,
          });
        });

        setMarks(mr);
        setDataLoader(false);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const marksField = (fieldName) => {
    if (savedMarks && savedMarks.length > 0 && savedMarks[0][fieldName])
      return savedMarks[0][fieldName];
  };

  useEffect(() => {
    //if (!props.pageType && props.pageType != "edit") {
    {
      let m = [];
      for (let i = 0; i < subjectCount; i++) {
        m.push(getMarksInfo(i));
        /* getMarksInfo(false, i == 0 ? "Language" : "")
        if (i == 0 && board == "state") m.push(getMarksInfo(5));
        else if (i == 1 && board == "state") m.push(getMarksInfo(6));
        else m.push(getMarksInfo(false, i == 0 ? "Language" : "")); */
      }

      console.log(m);
      setMarks(m);
    }
  }, [minMark, maxMark, subjectCount, board]);

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.UG.APPLICANT_MIN_INFO_NEW, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;
          /*setMinMarks(d.year_of_passing == "2019" ? 35 : 70);
          setMaxMark(d.year_of_passing == "2019" ? 100 : 200);*/
          //setMinMarks(35);
          setMaxMark(100);
          setSubjectCount(d.board == "state" ? 6 : 5);
          setBoard(d.board);
          setCommunity(d.community);
          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const loadHscSubjects = () => {
    try {
      setLoader(true);
      setHscSubjects([]);
      axios.get(ServiceUrl.UG.HSC_SUBJECTS).then((res) => {
        if (res["data"].status == "1") {
          setHscSubjects(res["data"].data);
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const days = () => {
    let rv = [];
    Array.from({ length: 31 }, (v, i) => {
      rv.push(<option value={i + 1}>{i + 1}</option>);
    });
    return rv;
  };

  const months = (sel) => {
    let rv = [];
    Array.from({ length: 12 }, (v, i) => {
      let n = ("0" + (i + 1)).slice(-2);
      rv.push(
        <option
          value={("0" + n).slice(-2)}
          selected={sel == i + 1 ? "selected" : ""}
        >
          {numberToMonth(n)}
        </option>
      );
    });
    return rv;
  };

  const years = (sel) => {
    let rv = [];
    Array.from({ length: 10 }, (v, i) => {
      let n = new Date().getFullYear() - i;
      rv.push(
        <option value={n} selected={sel == n ? "selected" : ""}>
          {n}
        </option>
      );
    });
    return rv;
  };

  const marksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    /*  if (
      parseFloat(v) < parseFloat(ds.min) ||
      parseFloat(v) > parseFloat(ds.max)
    ) {
      toast.error("Enter correct mark");
      e.target.value = "";
      return;
    } */
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index]["mark_scored"] = v;
      setMarks(m);
    }
  };

  const getSubjectOptions = () => {
    let rv = [];
    hscSubjects.map((item, i) => {
      let index = marks.findIndex((obj) => obj.subject_id == item.id);
      //if (index == -1) rv.push(<Option value={item.id}>{item.name}</Option>);
      if (index == -1) rv.push({ value: item.id, label: item.name });
    });
    return rv;
  };

  const handleSubjectChange = (index, v) => {
    let m = [...marks];
    m[index]["subjectname"] = v.target.value;
    console.log(m);
    setMarks(m);
  };

  const handleInputChange = (item, fieldName, e) => {
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index][fieldName] = e.target.value;
      setMarks(m);
    }
  };

  const getTotalByFieldName = (fieldName) => {
    let m = marks;
    let total = 0;
    m.map((item, i) => {
      let v = item[fieldName];
      if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
    });
    return parseFloat(total);
  };

  const part3Total = () => {
    let m = marks;
    let total = 0;

    m.map((item, i) => {
      //if (item.subject_id != 5 && item.subject_id != 6) {

      if (applicant.board == "state" && item.subject_id != 5) {
        let v = item.mark_scored;
        if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
      } else if (applicant.board != "state" && i != 0) {
        let v = item.mark_scored;
        if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
      }
    });
    return parseFloat(total);
  };

  const part3Percentage = () => {
    let total = part3Total();
    return parseFloat((total / 500) * 100).toFixed(2);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      toast.error("Please fill all the required fields.");
      return;
    }

    /*  let m = marks.find((item) => !item.subject_id);
    if (m) {
      toast.error("Please select all the subjects and marks");
      return;
    } */

    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);

    let url = ServiceUrl.UG.STEP_TWO_NEW;
    if (props.pageType && props.pageType == "edit")
      url = ServiceUrl.UG.UPDATE_STEP_TWO_NEW;

    axios.post(url, $("#frmStepII").serialize()).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");

        context.setLoader(false);
        setLoader(false);
        loadCaptcha();
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <StepMenu activeKey="step2" pageType={props.pageType} />

            {dataLoader && (
              <div className="text-center my-5">
                <Spinner />
              </div>
            )}
            {!dataLoader && applicant.board && (
              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Spin spinning={loader}>
                    <Form
                      noValidate
                      validated={validated}
                      encType="multipart/form-data"
                      id="frmStepII"
                      method="post"
                      onSubmit={handleFormSubmit}
                    >
                      <input
                        type="hidden"
                        name="i__userId"
                        value={context.user.user_id}
                      />
                      <input
                        type="hidden"
                        name="i__userCode"
                        value={context.user.user_code}
                      />
                      <input
                        type="hidden"
                        name="i__apiCode"
                        value={context.user.api_code}
                      />
                      <input
                        type="hidden"
                        name="marks"
                        value={JSON.stringify(marks)}
                      />

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Details of Qualification</h5>
                        </Col>
                      </Row>

                      <input type="hidden" name="i__medium" value="english" />

                      {/* <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Type of School
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                as="select"
                                className="form-select form-select-sm fw-bold"
                                name="i__schoolType"
                                size="sm"
                                defaultValue={lowerCase(
                                  marksField("school_type")
                                )}
                                required
                              >
                                <option value=""> -Select- </option>
                                <option value="govt"> Government </option>
                                <option value="govt aided">
                                  {" "}
                                  Government Aided{" "}
                                </option>
                                <option value="private"> Private </option>
                              </Form.Control>
                            </Col>
                          </Row>
 */}
                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Roll No
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__rollno"
                            size="sm"
                            placeholder="roll no"
                            defaultValue={
                              marks && marks.length > 0 && marks[0].register_no
                            }
                            required
                          />
                        </Col>
                      </Row>

                      {/* <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Name of the School Studied
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__school"
                            size="sm"
                            defaultValue={marksField("school_name")}
                            placeholder="Name of the School"
                            onKeyPress={(e) => lettersOnly(e)}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Place of the School Studied
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__schoolPlace"
                            size="sm"
                            placeholder="Place of the School"
                            defaultValue={marksField("school_place")}
                            required
                          />
                        </Col>
                      </Row> */}

                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Class X Passing Year
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="number"
                            className="text-uppercase fw-bold"
                            name="i__xpassYear"
                            size="sm"
                            placeholder="Class X Passing Year"
                            defaultValue={marksField("xpass_year")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Class X School Name
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="fw-bold"
                            name="i__xschoolName"
                            size="sm"
                            placeholder="Class X School Name"
                            defaultValue={marksField("xschool_name")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Class XII Passing Year
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="number"
                            className="fw-bold"
                            name="i__xiipassYear"
                            size="sm"
                            placeholder="Class XII Passing Year"
                            defaultValue={marksField("xiipass_year")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 ">
                        <Col md={4}>
                          <label>
                            Class XII School Name
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="fw-bold"
                            name="i__xiischoolName"
                            size="sm"
                            placeholder="Class XII School Name"
                            defaultValue={marksField("xiischool_name")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="context_box_title mt-3">
                        <Col md={12}>
                          <h5>Details of Marks Obtained in Class XII</h5>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <div className="table-responsive">
                            <table className="table table-sm table-bordered hsc-mark-table">
                              <thead className="bg-light">
                                <tr>
                                  <th width="250">Subject</th>
                                  <th>Marks Scored</th>
                                  <th>Maximum Marks</th>
                                  {/* <th>Month & Year of Passing</th> */}
                                  <th>No.of Attempts</th>
                                  {/* <th>Register No</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {marks.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {item.subject_information && (
                                          <span>
                                            {item.subject_information}
                                          </span>
                                        )}
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          defaultValue={item.subject_name}
                                          onBlur={(e) =>
                                            handleSubjectChange(i, e)
                                          }
                                          //disabled={!item.subjectname}
                                          /* onChange={(v) =>
                                            handleSubjectChange(i, v)
                                          } */
                                          required
                                        />
                                        {/* <Select
                                          showSearch
                                          style={{ width: "100%" }}
                                          placeholder="Select Subject"
                                          optionFilterProp="children"
                                          className="fw-bold"
                                          filterOption={(input, option) =>
                                            lowerCase(
                                              option?.label ?? ""
                                            ).includes(lowerCase(input))
                                          }
                                          onChange={(v) =>
                                            handleSubjectChange(i, v)
                                          }
                                          defaultValue={item.subjectname}
                                          options={getSubjectOptions()}
                                        ></Select> */}
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          data-min={minMark}
                                          data-max={maxMark}
                                          min={minMark}
                                          max={maxMark}
                                          onWheel={(e) => e.target.blur()}
                                          onBlur={(e) =>
                                            marksInputOnBlur(item, e)
                                          }
                                          required
                                          defaultValue={item.mark_scored}
                                          step=".01"
                                          //disabled={!item.subject_id}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          value={item.mark_maximum}
                                          //  disabled={!item.subject_id}
                                          required
                                        />
                                      </td>
                                      {/* <td>
                                        <Form.Control
                                          as="select"
                                          size="sm"
                                          className="fw-bold"
                                          disabled={!item.subject_id}
                                          onChange={(e) =>
                                            handleInputChange(
                                              i,
                                              "pass_month",
                                              e
                                            )
                                          }
                                          required
                                        >
                                          {months(item.pass_month)}
                                        </Form.Control>
                                        <Form.Control
                                          as="select"
                                          size="sm"
                                          className="fw-bold"
                                          // disabled={!item.subject_id}
                                          onChange={(e) =>
                                            handleInputChange(i, "pass_year", e)
                                          }
                                          required
                                        >
                                          {years(item.pass_year)}
                                        </Form.Control>
                                      </td> */}
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          defaultValue={
                                            item.no_of_attempts
                                              ? item.no_of_attempts
                                              : 1
                                          }
                                          //disabled={!item.subject_id}
                                          min={1}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item,
                                              "no_of_attempts",
                                              e,
                                              i
                                            )
                                          }
                                          onWheel={(e) => e.target.blur()}
                                          required
                                        />
                                      </td>
                                      {/* <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          value={item.register_no}
                                          // disabled={!item.subject_id}
                                          min={0}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item,
                                              "register_no",
                                              e
                                            )
                                          }
                                          onWheel={(e) => e.target.blur()}
                                          required
                                        />
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="fw-bold"
                                      size="sm"
                                      name="scored_total"
                                      value={getTotalByFieldName("mark_scored")}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="fw-bold"
                                      size="sm"
                                      name="max_total"
                                      value={getTotalByFieldName(
                                        "mark_maximum"
                                      )}
                                      required
                                    />
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Marks scored out of {"500"}
                            {/*maxMark == 200 ? "800" : "400"*/}
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_total"
                            size="sm"
                            value={part3Total()}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            First Five Marks Percentage (%)
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_percentage"
                            size="sm"
                            value={part3Percentage()}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        {props.pageType && props.pageType == "edit" && (
                          <Col md={6}>
                            <Link to="/" className="btn btn-danger w-100">
                              Cancel
                            </Link>
                          </Col>
                        )}
                        <Col
                          md={
                            props.pageType && props.pageType == "edit" ? 6 : 12
                          }
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="w-100"
                            disabled={loader}
                          >
                            Save & Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            Please check all the filled informations before processing. Do you
            want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default StepTwo;
