import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";

import PsContext from "../../../context";
import { Card } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { Spin } from "antd";

const Announcement = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoader(true);
    axios.get(ServiceUrl.ADMISSION.ANNOUNCEMENTS).then((res) => {
      if (res["data"].status == "1") {
        setDataList(res["data"].data);
      }
      setLoader(false);
    });
  };

  const marqueeMouseOver = () => {
    document.getElementById("adm_top_marquee").stop();
  };

  const marqueeMouseOut = () => {
    document.getElementById("adm_top_marquee").start();
  };

  const renderHtmlText = () => {
    return dataList.map((item) => (
      <li style={{ display: "inline-block " }}>
        <div
          className="px-4"
          dangerouslySetInnerHTML={{ __html: item.content_html }}
        />
      </li>
    ));
  };

  function isMobileBrowser() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  return isMobileBrowser() ? (
    <>
      <Card className="rounded card-border-btm" style={{ height: "300px" }}>
        <Card.Header className="fw-bold">Announcements</Card.Header>
        <Card.Body>
          <Spin spinning={loader}>
            <marquee
              onMouseOver={marqueeMouseOver}
              onMouseOut={marqueeMouseOut}
              id="adm_top_marquee"
              direction="up"
              scrollamount="4"
              style={{ height: "25vh" }}
            >
              <ul className="top_marquee px-0 py-0 mx-0 my-0">
                {renderHtmlText()}
              </ul>
            </marquee>
          </Spin>
        </Card.Body>
      </Card>
    </>
  ) : (
    <Card className="rounded card-border-btm">
      <Card.Header className="fw-bold">Announcements</Card.Header>
      <Card.Body>
        <Spin spinning={loader}>
          <marquee
            onMouseOver={marqueeMouseOver}
            onMouseOut={marqueeMouseOut}
            id="adm_top_marquee"
            direction="up"
            scrollamount="4"
            style={{ height: "54vh" }}
          >
            <ul className="top_marquee px-0 py-0 mx-0 my-0">
              {renderHtmlText()}
            </ul>
          </marquee>
        </Spin>
      </Card.Body>
    </Card>
  );
};

export default Announcement;
