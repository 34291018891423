import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { numberToMonth, upperCase } from "../../../utils";

const QualificationDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    try {
      if (props.data && props.data[0][fieldName])
        return props.data[0][fieldName];
    } catch (er) {}
  };

  const personalField = (fieldName) => {
    try {
      if (props.personal && props.personal[fieldName])
        return props.personal[fieldName];
    } catch (er) {}
  };

  return (
    <>
      {/*} <InputField
        label="Medium of Study"
        value={upperCase(field("study_medium"))}
  />*/}

      <InputField label="Roll No" value={upperCase(field("register_no"))} />
      <InputField
        label="Class X Passing Year"
        value={upperCase(field("xpass_year"))}
      />
      <InputField
        label="Class X School Name"
        value={upperCase(field("xschool_name"))}
      />
      <InputField
        label="Class XII Passing Year"
        value={upperCase(field("xiipass_year"))}
      />
      <InputField
        label="Class XII School Name"
        value={upperCase(field("xiischool_name"))}
      />

      {/* {upperCase(personalField("board")) != "STATE" && (
        <>
          <InputField
            label="Type of School"
            value={upperCase(field("school_type"))}
          />

          <InputField label="Name of the School" value={field("school_name")} />

          <InputField
            label="Place of the School"
            value={field("school_place")}
          />
        </>
      )}

      {upperCase(personalField("board")) == "STATE" && (
        <>
          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-light">
                <th width="100">Class</th>
                <th>School Name</th>
                <th width="150">Medium</th>
                <th width="200">School Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6th - 10th</td>
                <td>{upperCase(field("6_to_10_school_name"))}</td>
                <td>{upperCase(field("6_to_10_medium"))}</td>
                <td>{upperCase(field("6_to_10_school_type"))}</td>
              </tr>
              <tr>
                <td>11th - 12th</td>
                <td>{upperCase(field("11_to_12_school_name"))}</td>
                <td>{upperCase(field("11_to_12_medium"))}</td>
                <td>{upperCase(field("11_to_12_school_type"))}</td>
              </tr>
            </tbody>
          </table>
        </>
      )} */}

      <div className="table-responsive mt-3">
        <table className="table table-sm table-bordered hsc-mark-table">
          <thead>
            <tr className="bg-light">
              <th>Subject Name</th>
              <th>Scored</th>
              <th>Maximum Marks</th>
              <th>No.of Attempts</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.subject_name}</td>
                  <td align="right">{item.mark_scored}</td>
                  <td align="right">{item.mark_maximum}</td>
                  {/*  <td align="center">
                    {numberToMonth(item.pass_month)} / {item.pass_year}
                  </td>
                  <td align="center">{item.register_no}</td> */}
                  <td align="center">{item.no_of_attempts}</td>
                </tr>
              );
            })}
            <tr>
              <td>Total</td>
              <td align="right" className="bg-light fw-bold">
                {field("scored_total")}
              </td>
              <td align="right" className="bg-light fw-bold">
                {field("max_total")}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <InputField
        label="Marks scored in Part III"
        value={field("part3_total")}
      />

      <InputField
        label="Part III Percentage"
        value={field("part3_percentage")}
      />

      {/* {upperCase(personalField("community")) != "OC" && (
        <>
          <Row className="context_box_title mb-3 mt-3">
            <Col md={12}>
              <h5>Community Details </h5>
            </Col>
          </Row>

          <InputField
            label="Community"
            value={upperCase(personalField("community"))}
          />

          <InputField
            label="Name of the Caste"
            value={upperCase(personalField("caste"))}
          />

          <InputField
            label="Issuing Authority"
            value={upperCase(field("issuing_authority"))}
          />

          <InputField
            label="Certificate No"
            value={upperCase(field("community_certificate_no"))}
          />

          <InputField
            label="Date of Issue"
            value={upperCase(field("community_certificate_issue_on"))}
          />

          <InputField label="District" value={upperCase(field("district"))} />

          <InputField label="Taluk" value={upperCase(field("taluk"))} />
        </>
      )} */}
    </>
  );
};

export default QualificationDetails;
