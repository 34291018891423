import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { IMAGES } from "../../../utils/data";
import { Col, Row } from "react-bootstrap";
import SignUpLink from "../../signUp/signUpLink";
import LoginForm from "../../login/loginForm";
import Announcement from "./announcement";
import HelpLineInfo from "./helpLineInfo";

const HomePage = (props) => {
  const context = useContext(PsContext);
  function isMobileBrowser() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return isMobileBrowser() ? (
    <>
      <div className="container">
        <Row>
          <Col md={7} className="d-sm-block">
            <Announcement />
          </Col>
          <Col md={5} className="mt-3">
            <div className="pt-2" style={{ textAlign: "center" }}>
              <Link
                to="/sign-in"
                className="ms-3 btn btn-success btn-sm rounded-pill px-3"
              >
                SIGN IN
              </Link>
              <Link
                to="/sign-up"
                className=" ms-3 btn btn-primary btn-sm rounded-pill px-3"
              >
                NEW REGISTRATION
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <HelpLineInfo />
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <>
      <div className="container">
        <Row>
          <Col md={7} className="d-none d-sm-block">
            <Announcement />
          </Col>
          <Col md={5}>
            <LoginForm />
          </Col>
          <Col md={7} className="d-sm-block d-md-none mt-3">
            <Announcement />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <HelpLineInfo />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(HomePage);
